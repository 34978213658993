import React, { useState, useRef, useEffect } from 'react';
import { Typography, Box, useTheme } from '@mui/material';

const TruncatedText = ({ text, maxHeight = 100, className, children }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const textRef = useRef(null);
  const theme = useTheme(); // Use the theme

  useEffect(() => {
    const checkOverflow = () => {
      const element = textRef.current;
      if (element && maxHeight !== 'none') {
        setIsOverflowing(element.scrollHeight > maxHeight);
      } else {
        setIsOverflowing(false);
      }
    };

    checkOverflow();
    window.addEventListener('resize', checkOverflow);
    return () => window.removeEventListener('resize', checkOverflow);
  }, [text, maxHeight]);

  return (
    <Box>
      <Box
        ref={textRef}
        sx={{
          maxHeight: isExpanded || maxHeight === 'none' ? 'none' : maxHeight,
          overflow: 'hidden',
          whiteSpace: 'pre-line',  // This preserves line breaks
          wordBreak: 'break-word', // Ensures words break properly
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpanded || maxHeight === 'none' ? 'none' : Math.floor(maxHeight / 24), // Assuming 24px line height
        }}
      >
        <Typography className={className} component="div">
          {children}
        </Typography>
      </Box>
      {isOverflowing && (
        <Box
          onClick={() => setIsExpanded(!isExpanded)}
          sx={{
            cursor: 'pointer',
            color: theme.palette.primary.light,
            fontSize: '16px',
            mt: 2,
            mb: 2,
            textDecoration: 'underline',
            fontWeight: 'bold',
          }}
        >
          {isExpanded ? 'Read Less' : 'Read More'}
        </Box>
      )}
    </Box>
  );
};

export default TruncatedText;