import React from "react";
import { usePlacesWidget } from "react-google-autocomplete";
import BaseInput from "ui/base/input/BaseInput";
import BaseSelector from "ui/base/input/BaseSelector";
import { Grid, Stack } from "@mui/material";
import { useTranslation } from "react-i18next";
import { isAddressValid } from "helpers";
import { countries } from "configuration/constants";
import _ from "lodash";

const countryOptions = Object.entries(countries).map((c) => ({
  value: c[0],
  label: JSON.parse(`"${c[1]}"`),
}));

const GOOGLE_API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;

export default function AddressAutocomplete({
  address,
  onChange,
  error,
  required,
}) {
  const { t } = useTranslation();
  const placeSelected = React.useRef(false);
  const addressFieldsVidisble = React.useRef(false);
  const [showAddressFields, setShowAddressFields] = React.useState(null);

  React.useEffect(() => {
    if (error && !!address?.formatted && !addressFieldsVidisble.current) {
      setShowAddressFields({
        street: true,
        city: true,
        country: true,
        state: true,
        zip_code: true,
      });
    }
  }, [error, address]);

  React.useEffect(() => {
    addressFieldsVidisble.current = !!showAddressFields;
  }, [showAddressFields]);

  const onPlaceSelected = (place, inputRef) => {
    placeSelected.current = true;
    setShowAddressFields((prev) => null);
    let hasErrors = false;
    const newAddress = {
      apt_number: "",
      city: "",
      country: "",
      state: "",
      street: "",
      zip_code: "",
      formatted: inputRef.value,
      lat: place.geometry?.location.lat() || "",
      lng: place.geometry?.location.lng() || "",
    };

    _.each(place.address_components || [], (addrComp) => {
      if (addrComp.types.includes("street_number")) {
        newAddress.street += `${addrComp.long_name}`;
      }
      if (addrComp.types.includes("route")) {
        newAddress.street = !!newAddress.street
          ? `${newAddress.street} ${addrComp.long_name}`
          : addrComp.long_name;
      }
      if (addrComp.types.includes("locality")) {
        newAddress.city = addrComp.long_name;
      }
      if (
        !newAddress.city &&
        addrComp.types.includes("administrative_area_level_2")
      ) {
        newAddress.city = addrComp.long_name;
      }
      if (!newAddress.city && addrComp.types.includes("postal_town")) {
        newAddress.city = addrComp.long_name;
      }
      if (addrComp.types.includes("country")) {
        newAddress.country = addrComp.short_name;
      }
      if (addrComp.types.includes("postal_code")) {
        newAddress.zip_code = addrComp.long_name;
      }
      if (addrComp.types.includes("administrative_area_level_1")) {
        newAddress.state = addrComp.long_name;
      }
    });

    let validAddressObj = isAddressValid(newAddress, true);
    if (!!required) {
      if (!validAddressObj.valid) {
        hasErrors = true;
        setShowAddressFields((prev) => validAddressObj.errors);
      } else if (addressFieldsVidisble.current) {
        hasErrors = false;
        addressFieldsVidisble.current = false;
        setShowAddressFields((prev) => null);
      }
    }
    onChange(newAddress, hasErrors);
  };

  const handleAddressChange = (e) => {
    const val = e.target.value;
    if (placeSelected.current && !val) {
      placeSelected.current = false;
      const newAddress = {
        apt_number: "",
        city: "",
        country: "",
        state: "",
        street: "",
        zip_code: "",
        formatted: val,
        lat: "",
        lng: "",
      };
      onChange(newAddress, required);
    } else {
      onChange({ ...address, formatted: val });
    }
  };

  const { ref } = usePlacesWidget({
    apiKey: GOOGLE_API_KEY,
    onPlaceSelected: onPlaceSelected,
    options: {
      types: ["address"], // https://developers.google.com/maps/documentation/places/web-service/supported_types#table3
      fields: ["address_components", "formatted_address", "geometry.location"], // https://developers.google.com/maps/documentation/javascript/reference/places-service#PlaceResult
    },
  });

  function getMissingAddressField(field) {
    let name = t(field);
    return (
      <BaseInput
        fullWidth
        label={name}
        value={address?.[field] ?? ""}
        error={!!error && !!showAddressFields?.[field] && !address?.[field]}
        onChange={(e) => onChange({ ...address, [field]: e.target.value })}
      />
    );
  }

  function getCountryAddressField(field) {
    return (
      <BaseSelector
        fullWidth
        label={t("country")}
        id={"country-select"}
        value={address?.country ?? ""}
        defaultOption={t("select_country")}
        error={!!error && !!showAddressFields?.[field] && !address?.country}
        onChange={(e) => onChange({ ...address, country: e.target.value })}
        options={countryOptions}
      />
    );
  }

  return (
    <Stack
      flexDirection={"column"}
      gap={2.5}
      borderRadius="15px"
      padding={!!showAddressFields ? 1.5 : 0}
      sx={(theme) => ({
        border: !!showAddressFields
          ? `1px solid ${theme.palette.primary.main}`
          : "none",
      })}
    >
      <BaseInput
        fullWidth
        id={"address-field"}
        inputRef={ref}
        required={required}
        label={t("address")}
        placeholder={t("address")}
        error={!!error && !showAddressFields}
        customErrorLabel={
          !address?.formatted ? t("please_fill_in") : t("address_error")
        }
        onChange={handleAddressChange}
      />
      {!!showAddressFields && (
        <Grid container spacing={2}>
          {showAddressFields.street && (
            <Grid item xs={12}>
              {getMissingAddressField("street")}
            </Grid>
          )}
          {showAddressFields.city && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("city")}
            </Grid>
          )}
          {showAddressFields.country && (
            <Grid item xs={12} sm={6}>
              {getCountryAddressField()}
            </Grid>
          )}
          {showAddressFields.state && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("state")}
            </Grid>
          )}
          {showAddressFields.zip_code && (
            <Grid item xs={12} sm={6}>
              {getMissingAddressField("zip_code")}
            </Grid>
          )}
        </Grid>
      )}
    </Stack>
  );
}
