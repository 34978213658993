import React, { Fragment, useState, useEffect, useContext, useRef } from "react";
import {
  Button,
  IconButton,
  Snackbar,
  ButtonBase,
  Typography,
  Link,
  Rating,
  CircularProgress,
  Box,
  Modal as MuiModal,
  Stack,
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTheme } from '@mui/material/styles';
import { useNavigate, useParams } from "react-router-dom";
import InfoIcon from '@mui/icons-material/Info';
import { isMacOs, isIOS } from "react-device-detect";
import { useTranslation } from "react-i18next";
// components
import Modal from 'components/Modal.jsx';
import LargeButton from 'components/LargeButton.jsx';
import BrandingFooter from 'components/BrandingFooter.jsx';
import LockButton from 'components/LockButton';
import CommonAccessCode from 'components/CommonAccessCode.jsx'
import { AdjustedDateTime, getAppleWallet, nTimeHelper } from 'helpers';
import InfoCTACard from "ui/base/card/InfoCTACard";
import LocalServicesGrid from "./Grids/LocalServicesGrid";
import SectionHeaderCard from "./Cards/SectionHeaderCard";
import GuidebooksGrid from "./Grids/GuidebooksGrid";
import Upsells from "ui/component/upsell/Upsells";
import ProductList from "ui/component/product/list/ProductList";
import BasePage from "ui/base/panel/BasePage";
import CheckSteps from "./CheckSteps";
import ContactInfo from "components/ContactInfo";
// Assets & Utils
import { checkinPost, checkoutPost, loadData, loadProductData, unlockPost, verifyPost } from 'api.js'
import { ConciergeIcon } from 'assets/BrandIcons.jsx';
import AddressIcon from 'assets/AddressIcon.jsx';
import { AppleWalletIcon } from 'assets/Icons.jsx';
import { getPurchase, savePurchase } from "apiActions";
import { AppContext } from "App";


const useStyles = makeStyles((theme) => ({
  image_text: {
    width: '100vw',
    height: '100%',
    paddingTop: '100px',
    display: 'flex',
    justifyContent: 'center'
  },
  close_button: {
    position: 'absolute',
    top: 15,
    left: 15,
  },
  checkin_button: {
    [theme.breakpoints.up('md')]: {
      display: 'none'
    },
  },
  checkin_button_desktop: {
    [theme.breakpoints.down('md')]: {
      display: 'none'
    },
  },
  line: {
    height: 1,
    border: 'none',
    opacity: '10%',
    backgroundColor: theme.palette.primary.main,
    width: '100%',
    margin: 0,
  },
  container: {
    padding: theme.spacing(0, 2.5),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(3),
    paddingTop: "40px",
  },
}));


function VerifiedPage({ setView, handleSwitchBoardingPass }) {
  const { listing_id } = useParams();
  const classes = useStyles();
  const theme = useTheme();
  const navigate = useNavigate()
  const { t } = useTranslation();
  const { kc, view, auth, setKc, setHideNavbar, setAlertOptions, loadingProducts, setLoadingProducts } = useContext(AppContext)
  const prevView = useRef(null)
  const didLoadViator = useRef(false)
  const [snackbar, setSnackBar] = useState(false);
  const [rating, setRating] = useState(null);
  const [open, setOpen] = useState(false)
  const [checkStepsOpen, setCheckStepsOpen] = useState(null)
  const [fullListOpen, setFullListOpen] = useState(false)
  const [productsListData, setProductsListData] = useState({
    initialTag: null,
    initialProducts: [],
  });
  let components = kc?.components ?? {}
  let booking_id = kc?.booking_id
  let hasAddress = !!components?.listing?.line1 || !!components?.listing?.line2 || !!components?.listing?.line3
  let hasUpsells = !!(components?.upsells || []).filter(up => up.url === 'payment_link').length
  let hasGuidebooks = !!(components?.guidebooks || []).length
  const checkin = view == "verified"
  const checkUpsell = components?.upsells?.find(upsell => upsell.product_type == (checkin ? "checkin" : "checkout"))
  const otherBkgs = components?.bookings || []
  const CTAs = [...(components?.pending_upsells || []), ...(components?.requires_action_upsell || [])]

  const [ctaConfirmButtonDisabled, setCtaConfirmButtonDisabled] = useState(false)
  const [requiresActionModal, setRequiresActionModal] = useState(false)
  const [requiresActionUpsell, setRequiresActionUpsell] = useState({ action: null, product: null, url: null })
  const [isCalling, setIsCalling] = useState(false)
  const [modalContent, setModalContent] = useState(null);


  useEffect(() => {
    if (!components?.requires_action_upsells?.length) { return }
    let confirmUpsell = components?.requires_action_upsells[0]
    let product = components?.upsells.find(e => e.product_id === confirmUpsell.product_id)
    getPurchase({
      auth, booking_id, product_id: product?.product_id, resource: 'up',
      onSuccess: (response) => {
        let action = response.data.url[confirmUpsell.sku_id]
        if (!!action && !action.external_redirect) {
          action = {
            body: {
              auth: auth,
              session_id: action.session_id,
              sku_id: confirmUpsell.sku_id,
              resource: "up",
              booking_id: booking_id
            }
          }
        } else {
          action = action.url
        }
        setRequiresActionUpsell({
          action: confirmUpsell,
          product: product,
          url: action
        })
        setRequiresActionModal(true)
      },
    })
  }, [components])

  useEffect(() => {
    if (!kc
      || !kc?.components
      || !["start_with_info", "verified", "checked_in"].includes(view)
      || loadingProducts
    ) {
      return;
    }

    if (kc.components.hasOwnProperty("viator") || kc.components.hasOwnProperty("upsells")) {
      didLoadViator.current = true;
    } else if (!didLoadViator.current) {
      didLoadViator.current = true;
      loadKeycardProducts();
    }
  }, [view, loadingProducts, kc]);


  function loadKeycardProducts() {
    const productPromise = loadProductData(auth, listing_id, kc?.booking_id ?? null, true);
    setLoadingProducts(true)
    productPromise.then(async r => {
      const updated_components = { ...(kc.components || {}), ...r.data };
      setKc({ ...kc, components: updated_components })
    }).catch(e => {
      console.log("ERROR GETTING PRODUCT DATA", e);
    }).finally(() => setLoadingProducts(false));
  }

  function handleCheckin(checkinComponents) {
    if (checkinComponents || !!components.checkin) {
      setHideNavbar(true)
      setCheckStepsOpen(prev => 'check_in_steps')
    } else {
      setOpen(true)
    }
  }

  function handleCheckout() {
    if (!!components.checkout) {
      setHideNavbar(true)
      setCheckStepsOpen(prev => 'check_out_steps')
    } else {
      setOpen(true)
    }
  }

  function handleVerify() {
    if (!components.verification_available) {
      setOpen(true)
    } else {
      setView('verify')
    }
  }


  function openUrl(product) {
    let upgradesPath = '/upgrade'
    let path = !!listing_id
      ? `/${listing_id}${upgradesPath}/${product.product_id}`
      : `${upgradesPath}/${product.product_id}/?auth=${auth}`
    setOpen(false)
    navigate(path)
  }

  const handleUnlock = async (device_id, action) => {
    let response = await unlockPost(auth, booking_id, device_id, action)
    let new_devices = Object.assign({}, components.devices)
    let curr_device = new_devices[device_id]
    new_devices[device_id] = {
      ...curr_device,
      smart_lock: response.data.status || curr_device.smart_lock,
      smart_lock_code: response.data.recovery_code || response.data.access_code || curr_device?.smart_lock_code,
      recovery_code: Boolean(response.data.recovery_code),
      error: response.data.error
    }
    setKc({ ...kc, components: { ...components, devices: new_devices } })
    return response
  }

  function checkinAction() {
    prevView.current = view
    setView('loading')
    checkinPost(auth, booking_id).then(r => {
      setHideNavbar(false)
      setKc({ ...kc, components: { ...components, view: 'checked_in' } })
      setView('checked_in')
    }).catch(err => {
      setAlertOptions({ open: true, message: t("checkin_error"), severity: 'error' })
      setView(prevView.current)
    })
  }

  function checkoutAction() {
    setView('loading')
    checkoutPost(auth, booking_id).then(r => {
      setHideNavbar(false)
      loadData(auth, listing_id, booking_id).then(newKeycard => {
        setKc(newKeycard.data)
        setView('checked_out')
      })
    })
  }

  const reloadKeycard = async () => {
    try {
      const r = await loadData(auth);
      if (r.data) {
        const updatedComponents = {
          ...kc.components,
          ...Object.keys(r.data.components).reduce((acc, key) => {
            if (r.data.components.hasOwnProperty(key)) {
              acc[key] = r.data.components[key];
            }
            return acc;
          }, {})
        };
        setKc({ ...r.data, components: updatedComponents });

        if (r.data.view === 'verified' && r.data.components.checkin) {
          const baseContent = getCheckinPrompt();
          const checkin_button = {
            text: t("checkin"),
            onClick: () => handleCheckin(r.data.components.checkin),
            lowContrast: true,
          };
          setModalContent({
            ...baseContent,
            buttons: [checkin_button]
          });
        } else {
          setModalContent(null);
        }
      }
    } catch (e) {
      console.log("ERROR GETTING KEYCARD", e);
    }
  };

  const handleOpenCheckinSteps = () => {
    setHideNavbar(true)
    setCheckStepsOpen('check_in_steps')
  }

  const handleCloseSteps = () => {
    setHideNavbar(false)
    setCheckStepsOpen(prev => null)
  }

  const header_image = <>
    <SectionHeaderCard
      disableGrayscale
      title={components?.listing?.name || (view == 'revoked' ? t('host_revoked_bp') : t("disabled_listing_contact_host"))}
      img={components?.listing?.picture}
      actions={<div className={classes.checkin_button_desktop}>
        {checkButton()}
      </div>}
    />
  </>

  const address = !!hasAddress && (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }} id="address">
        <Typography>{components?.listing?.line1}</Typography>
        <Typography>{components?.listing?.line2}</Typography>
        <Typography>{components?.listing?.line3}</Typography>
      </div>
      {!!components.listing && <IconButton onClick={() => window.open(components?.listing?.google_maps_link)}>
        <AddressIcon sx={{ fontSize: 34, color: theme.palette.primary.main, padding: 0 }} />
      </IconButton>}
    </div>
  )

  const localServices = <LocalServicesGrid preview divider={hasUpsells || hasGuidebooks} />
  const upsells = <Upsells preview />
  const products = fullListOpen ? (
    <MuiModal open>
      <div>
        <BasePage>
          <Box maxWidth={800} width={"100%"} height={"100%"}>
            <ProductList
              disableGutters
              initialData={productsListData}
              saveInitialData={(openFullList, tag, products) => {
                setProductsListData({ initialTag: tag, initialProducts: products });
                setFullListOpen(openFullList);
              }}
            />
          </Box>
        </BasePage>
      </div>
    </MuiModal>
  ) : (
    <Box>
      <ProductList
        preview
        disableGutters
        initialData={productsListData}
        saveInitialData={(openFullList, tag, products) => {
          setProductsListData({ initialTag: tag, initialProducts: products });
          setFullListOpen(openFullList);
        }}
      />
    </Box>
  );
  const guidebooks = <GuidebooksGrid preview divider={hasUpsells} />

  const weather = !!components?.listing?.weather && (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div style={{ flex: 1 }}>
        <Typography id="weather">{components?.listing?.weather}</Typography>
      </div>
    </div>
  )

  function Wifi() {
    return <Fragment>
      <div style={{ display: 'flex' }} id="wifi">
        <div style={{ flex: 1, marginRight: 20 }}>
          <Typography variant='body2'>{t("wifi_net").toUpperCase()}</Typography>
          <CopyToClipboard
            text={components.listing?.wifi.ssid}
            onCopy={() => setSnackBar(t("wifi_copied"))}
          >
            <Typography>{components.listing?.wifi.ssid}</Typography>
          </CopyToClipboard>
        </div>
        <div style={{ flex: 1 }}>
          <Typography variant='body2'>{t("password").toUpperCase()}</Typography>
          <CopyToClipboard
            text={components.listing?.wifi.password}
            onCopy={() => setSnackBar(t("password_copied"))}
          >
            <Typography>{components.listing?.wifi.password}</Typography>
          </CopyToClipboard>
        </div>
      </div>
      <hr className={classes.line} />
    </Fragment>
  }

  const checkSteps = !!checkStepsOpen && (
    <MuiModal open>
      <div>
        <CheckSteps
          checkin={checkStepsOpen === 'check_in_steps'}
          handleUnlock={handleUnlock}
          components={components}
          handleCheck={checkStepsOpen === 'check_in_steps' ? checkinAction : checkoutAction}
          setView={v => setView(v)}
          closePage={handleCloseSteps}
        />
      </div>
    </MuiModal>
  )


  function CheckDates() {
    return <div>
      <Typography variant='body2'>{t(components.booking.label)}</Typography>
      <Typography variant='h5'>{`${AdjustedDateTime(components.booking.value)}`}</Typography>
    </div>
  }

  function getLoader() {
    return {
      text: <>
        <CircularProgress />
        <Typography>{t("loading_wallet")}...</Typography>
      </>
    }
  }

  function getCheckinPrompt() {
    let header = null
    let text = null
    if (view == 'verified') {
      header = t("early_to_checkin")
      text = `${t("checkin_release")}.` + (checkUpsell ? t("meantime_purchase_checkin") : "")
    } else if (view == 'checked_in') {
      header = t("early_to_checkout")
      text = `${t("checkout_release")}.` + (checkUpsell ? t("meantime_purchase_checkout") : "")
    } else if (view == 'start_with_info') {
      header = t("early_to_verify")
      text = `${t("verification_release")}.`
    }
    let buttons = [
      {
        text: t("cancel"),
        lowContrast: false,
        onClick: () => setOpen(false)
      }
    ]
    if (!!checkUpsell) {
      buttons = [
        {
          text: checkin ? t("purchase_checkin") : t("purchase_checkout"),
          onClick: () => openUrl(checkUpsell),
          lowContrast: true,
        },
        ...buttons,
      ]
    } else {
      buttons[0].lowContrast = true
    }

    return {
      title: header,
      text: text,
      buttons: buttons
    }
  }


  function CheckTimes() {
    return <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        <Typography variant='body2'>{t("checkin").toUpperCase()}</Typography>
        <Typography variant='h5'>{nTimeHelper(components.listing?.checkin_time)}</Typography>
      </div>
      <div style={{ flex: 1 }}>
        <Typography variant='body2'>{t(t("checkout").toUpperCase())}</Typography>
        <Typography variant='h5'>{nTimeHelper(components.listing?.checkout_time)}</Typography>
      </div>
    </div>
  }

  function checkButton() {
    let button_type = null;
    let func = null;
    if (view === 'start_with_info') {
      button_type = 'verify'
      func = handleVerify
    } else if (view == 'verified') {
      button_type = 'check_in_steps'
      func = handleCheckin
    } else if (view == 'checked_in') {
      button_type = 'check_out_steps'
      func = handleCheckout
    } else {
      return <></>
    }

    return (
      <div>
        <LargeButton
          component_type={button_type}
          setView={v => func()}
        />
        {open && <Modal
          open={Boolean(open)}
          setOpen={setOpen}
          loading={isCalling}
          {...(open === 'loading'
            ? getLoader()
            : modalContent || getCheckinPrompt()
          )}
        />}
      </div>
    );
  }


  function unlockButtons() {
    let devices = Object.values(components.devices)
    let common_code = devices.every(d => d?.smart_lock_code == devices?.[0]?.smart_lock_code)
    return <>
      {common_code && <CommonAccessCode
        components={components}
        handleUnlock={handleUnlock}
      />}
      {Object.entries(components.devices).map(([device_id, d]) => {
        if (!d || !device_id) { return null }
        return <React.Fragment key={device_id}>
          <LockButton
            button_style='large'
            state={d}
            handleUnlock={handleUnlock}
            hideCode={common_code}
            device_id={device_id}
          />
        </React.Fragment>
      })}
    </>
  }

  const handleRating = (e, val) => {
    setRating(val)
    verifyPost(auth, booking_id, listing_id, { fields: [{ field: 'rating', value: val }] }, 'details')
      .then(r => {
        setKc({
          booking_id: r.data.booking_id,
          components: { ...r.data.components },
          view: r.data.view
        })
      })
  }

  function currentView() {
    switch (view) {
      case 'start_with_info':
        return <Fragment>
          {!!hasAddress && <hr className={classes.line} />}
          {weather}
          {!!components?.listing?.weather && <hr className={classes.line} />}
          {!!components?.contact && <ContactInfo components={components} />}
          {components.listing?.wifi?.ssid && Wifi()}
          {listing_id ? <CheckTimes /> : <CheckDates />}
          <div className={classes.checkin_button}>
            {checkButton()}
          </div>
          {checkSteps}
          {upsells}
          {products}
          {guidebooks}
          {localServices}
        </Fragment>
      case 'verified':
        return <Fragment>
          {!!hasAddress && <hr className={classes.line} />}
          {weather}
          {!!components?.listing?.weather && <hr className={classes.line} />}
          {!!components?.contact && <ContactInfo components={components} />}
          {components.listing?.wifi?.ssid && Wifi()}
          {listing_id ? <CheckTimes /> : <CheckDates />}
          <div className={classes.checkin_button}>
            {checkButton()}
          </div>
          {checkSteps}
          {upsells}
          {products}
          {guidebooks}
          {localServices}
        </Fragment>
      case 'checked_in':
        return <Fragment>
          {!!hasAddress && <hr className={classes.line} />}
          {weather}
          {!!components?.listing?.weather && <hr className={classes.line} />}
          {!!components?.contact && <ContactInfo components={components} />}
          {components.listing?.wifi?.ssid && Wifi()}
          {listing_id ? <CheckTimes /> : <CheckDates />}
          <div className={classes.checkin_button}>
            {checkButton()}
          </div>
          {(components?.checkin) && <Fragment>
            <Link style={{ fontSize: 22 }} onClick={handleOpenCheckinSteps}>
              {t("view_steps")}
            </Link>
            {unlockButtons()}
          </Fragment>}
          {checkSteps}
          {upsells}
          {products}
          {guidebooks}
          {localServices}
        </Fragment>
      case 'checked_out':
        let review = components?.review
        return <Fragment>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', marginTop: 30, marginBottom: 30 }}>
            <Typography>{t("how_was_stay")}</Typography>
            <div>
              <Rating
                value={rating}
                style={{ width: '100%', fontSize: 50, color: theme.palette.primary.main }}
                onChange={handleRating}
              />
            </div>
          </div>
          {(rating != null) && (rating < 5)
            ? <></>
            : review?.review_url && <LargeButton component_type='leave_review' setView={() => window.open(
              (review.review_url.startsWith("http") ? "" : "https://") + review.review_url
            )} />
          }
          {review?.booking_url && <LargeButton component_type='book_again' setView={() => window.open(
            (review.booking_url.startsWith("http") ? "" : "https://") + review.booking_url
          )} />}
        </Fragment>

      default: return null
    }
  }

  function requires_action_upsell() {
    let text = requiresActionUpsell.action.message
    let buttons = [
      {
        text: t("Confirm purchase"),
        disabled: ctaConfirmButtonDisabled,
        onClick: () => {
          setCtaConfirmButtonDisabled(true)
          if (requiresActionUpsell.url?.body) {
            setRequiresActionModal(false)
            savePurchase({
              body: requiresActionUpsell.url.body,
              setAlertOptions,
              onSuccess: r => {
                if (!r.data.success) {
                  setView('error')
                } else {
                  console.log("GOT PURCHASE RESPONSE", r)
                }
              }
            })
          } else {
            window.open(requiresActionUpsell.url)
            setRequiresActionModal(false)
          }
        }
      },
      {
        text: t("Cancel"),
        lowContrast: false,
        onClick: () => { setRequiresActionModal(false) }
      }
    ]
    return <div>
      {requiresActionModal && <Modal
        open={Boolean(requiresActionModal)}
        setOpen={(isOpen) => {
          setCtaConfirmButtonDisabled(false)
          setRequiresActionModal(isOpen)
        }}
        text={text}
        buttons={buttons}
      />}
    </div>
  }

  useEffect(() => {
    if (open && view == 'verified') {
      const reload = async () => {
        setIsCalling(true);
        try {
          await reloadKeycard();
        } finally {
          setIsCalling(false);
        }
      };
      reload(); // Call immediately
    }
  }, [open]);

  return <Fragment>
    <Snackbar
      open={Boolean(snackbar)}
      autoHideDuration={6000}
      onClose={() => setSnackBar(false)}
      message={snackbar}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    />
    {requiresActionModal && requires_action_upsell()}
    {header_image}
    <main>
      <div className={classes.container}>
        {(!!otherBkgs.length || !!CTAs.length) && (
          <Stack gap={1.5}>
            {otherBkgs.map((b) => (
              <InfoCTACard
                icon={<InfoIcon sx={{ fontSize: 30, color: theme.palette.primary.main }} />}
                message={`${t("have_another_booking")} ${b.listing}`}
                action={
                  <Button
                    onClick={() => handleSwitchBoardingPass(b.booking_id)}
                    variant="contained"
                    style={{ borderRadius: 20 }}
                  >{t("view")}</Button>
                }
              />
            ))}
            {CTAs.map((b, i) => (
              <InfoCTACard
                icon={<ConciergeIcon sx={{ color: theme.palette.primary.main }} />}
                message={b.message}
              />
            ))}
          </Stack>
        )}
        <Box mt={!hasAddress ? 0 : 1.5}>
          {address}
        </Box>
        {currentView()}
      </div>
    </main>
    <Box mt={7.5} display='flex' flexDirection='column' alignItems='center'>
      <BrandingFooter
        metadata={components?.metadata ?? { color1: '#FFFFFF', color2: '#000000' }}
        extras={
          view != 'revoked' && (isMacOs || isIOS) ?
            <ButtonBase
              style={{ marginLeft: 10, marginRight: 10 }}
              onClick={() => getAppleWallet(auth, booking_id, (val) => setOpen(!!val ? 'loading' : false))}
            >
              <AppleWalletIcon />
            </ButtonBase>
            :
            null
        }
      />
    </Box>
  </Fragment>
};

export default VerifiedPage
