import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { loadProductAvailability, loadProductDetails } from "../../../../api";
import { formatProduct, getTravelers } from "helpers";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../App";
import _ from "lodash";
// Components
import { Modal, Skeleton } from "@mui/material";
import HTMLContent from "components/HTMLContent";
import BaseProductDialog from "./BaseProductDialog";
import ViatorCheckoutDialog from "./ViatorCheckoutDialog";
import PrimaryButton from "ui/base/button/PrimaryButton";
import AvailabilityForm from "../form/AvailabilityForm";
import InfoCard from "ui/base/card/InfoCard";
import WarningTag from "ui/base/tag/WarningTag";
import GuidebookComponents from "components/GuidebookComponents";

const defaultContext = { adultRequired: false };
export const ViatorProductContext = createContext(defaultContext);

export default function ViatorProductDialog({
  product,
  setSelectedProduct,
  onClose,
}) {
  const contentRef = useRef(null);
  const { auth, kc, setHideNavbar } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const didMount = useRef(false);
  const backupFieldValuesRef = useRef({});
  const [productSkus, setProductSkus] = useState([]);
  const [skuIndex, setSkuIndex] = useState(0);
  const [availabilityFields, setAvailabilityFields] = useState([]);
  const [travelerDetails, setTravelerDetails] = useState(null);
  const [travelDate, setTravelDate] = useState(null);
  const [checkingAvailability, setCheckingAvailability] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false);
  const [backupFieldValues, setBackupFieldValues] = useState({});
  const [rawProduct, setRawProduct] = useState(product);
  const [loadingDetails, setLoadingDetails] = useState(true);
  const [errors, setErrors] = useState({ travelers: false, travelDate: false });
  const [updateAvailability, setUpdateAvailability] = useState(false);
  const [availabilityError, setAvailabilityError] = useState(null);
  const adultRequired =
    product?.metadata?.bookingRequirements?.requiresAdultForBooking;
  const hasAdults = useMemo(() => {
    return (travelerDetails || []).some(
      (adult) =>
        ["PAX_MIX_ADULT", "PAX_MIX_SENIOR"].includes(adult.field) &&
        !!adult.value
    );
  }, [travelerDetails]);
  const isAdultValid = adultRequired ? hasAdults : true;
  const skuNotAvailable = !productSkus[skuIndex]?.available;
  const language = kc?.components?.guest?.language ?? "en";
  const invalidDate = !travelDate || !!travelDate?.error || !travelDate?.value;
  const invalidTravelers =
    !travelerDetails ||
    !isAdultValid ||
    travelerDetails.every((tr) => !tr.value);
  const currency =
    product?.metadata?.pricing?.currency ?? product?.currency ?? "USD";
  const orderDetails = {
    product: product,
    currency,
    price: product?.skus?.[skuIndex]?.value ?? 0,
    travelers: travelerDetails ? getTravelers(travelerDetails, t) : "",
  };
  const cancellationPolicy = product.cancellation_policy?.description?.find((d) => d.language === language)?.value;

  useEffect(() => {
    if (updateAvailability) {
      setUpdateAvailability(false);
      checkAvailability();
    }
  }, [updateAvailability]);

  useEffect(() => {
    backupFieldValuesRef.current = backupFieldValues;
  }, [backupFieldValues]);

  useEffect(() => {
    didMount.current = true;
    return () => {
      didMount.current = false;
    };
  }, []);

  useEffect(() => {
    if (!!availabilityError) {
      scrollToBottom();
    }
  }, [availabilityError]);

  useEffect(() => {
    let currSku = {};
    if (productSkus?.length) {
      currSku = _.cloneDeep(productSkus[skuIndex]);
      const travelersIndex = currSku?.enter_details?.findIndex(
        (e_d) => e_d.field === "NUM_OF_TRAVELER"
      );
      currSku.enter_details[travelersIndex].error = errors.travelers;
    }
    setProductSkus((prev) =>
      prev.map((sku, ind) => {
        if (ind === skuIndex) {
          return currSku;
        } else {
          return sku;
        }
      })
    );
    if (product.availability_schedule?.error !== errors.travelDate) {
      setSelectedProduct({
        ...product,
        availability_schedule: {
          ...product.availability_schedule,
          error: errors.travelDate,
        },
      });
    }
  }, [errors, skuIndex]);

  const redirectToUpgrades = () => {
    navigate(`/?auth=${auth}`, {
      replace: true,
      state: { closeProduct: true },
    });
  };

  const getSku = (newSku) => {
    if (!newSku) {
      return null;
    }
    return {
      ...newSku,
      enter_details: newSku.enter_details.map((e_d) => ({
        ...e_d,
        error: backupFieldValues?.[e_d.field]?.error ?? e_d.error,
        value: backupFieldValues?.[e_d.field]?.value ?? e_d.value,
      })),
    };
  };

  const getUpdatedSkus = (newSkus) => {
    return newSkus.map((sk, index) => (index === skuIndex ? getSku(sk) : sk));
  };

  const updateProduct = (data) => {
    const formattedProduct = formatProduct(data, language);
    setSelectedProduct(formattedProduct);
    setRawProduct({
      ...data,
      skus: getUpdatedSkus(data.skus),
    });
  };

  useEffect(() => {
    const getProductDetails = async () => {
      try {
        const response = await loadProductDetails(
          auth,
          product.product_id,
          kc?.booking_id
        );
        if (response.data && didMount.current) {
          updateProduct(response.data);
          setProductSkus(response.data.skus);
        }
        setLoadingDetails(false);
      } catch (error) {
        const reason = error?.response?.data?.error?.[0]?.message;
        console.error("ERROR GETTING PRODUCT DETAILS", reason);
        redirectToUpgrades();
        setHideNavbar(false);
        setLoadingDetails(false);
      }
    };

    getProductDetails();
  }, [auth]);

  const onFieldChange = (e) => {
    let dateHasErrors = false;
    let travelersHasErrors = false;
    if (e.field_type === "date") {
      dateHasErrors = !e || !!e?.error || !e?.value;
      setErrors((prev) => ({ ...prev, travelDate: dateHasErrors }));
      setTravelDate(e);
      setAvailabilityFields([]);
      setSelectedProduct({
        ...product,
        availability_schedule: {
          ...product.availability_schedule,
          error: dateHasErrors,
        },
      });
    } else if (e.field_type === "traveler_input") {
      travelersHasErrors =
        !e.value || e.value.every((tr) => !tr.value) || !!e.error;
      setErrors((prev) => ({ ...prev, travelers: travelersHasErrors }));
      setTravelerDetails(e.value);
      setAvailabilityFields([]);
    }
    if (!dateHasErrors && !travelersHasErrors) {
      setUpdateAvailability(true);
    } else {
      scrollToBottom();
    }
  };

  const scrollToBottom = () => {
    const el = document.getElementById(
      loadingDetails ? "scrollable-page-container" : "product-sku-selector"
    );
    if (el && loadingDetails) {
      el.scrollTop = el.scrollHeight;
    } else if (el) {
      el.scrollIntoView({ block: "center", behavior: "smooth" });
    }
  };

  const checkAvailability = async (displayCheckoutPage) => {
    if (invalidTravelers || invalidDate) {
      if (displayCheckoutPage) {
        scrollToBottom();
        setErrors({ travelDate: invalidDate, travelers: invalidTravelers });
      }
      return;
    }

    setCheckingAvailability(true);
    if (!!availabilityError) {
      setAvailabilityError(null);
    }
    try {
      const response = await loadProductAvailability(
        auth,
        product.product_id,
        travelDate,
        travelerDetails
      );
      if (response.data) {
        updateProduct({
          ...response.data,
          availability_schedule: product.availability_schedule,
          metadata: {
            ...response.data.metadata,
            components: product.metadata?.components || []
          }
        });

        // Update current fields
        let newBackup = {};
        _.each(response.data.skus?.[skuIndex]?.enter_details, (e_d) => {
          newBackup[e_d.field] = {
            ...e_d,
            error:
              backupFieldValuesRef.current[e_d.field]?.error ??
              (e_d.error || false),
            value:
              backupFieldValuesRef.current[e_d.field]?.value ??
              (e_d.value || ""),
          };
        });
        setProductSkus((prev) =>
          response.data.skus.map((sk) => {
            const currentSku = prev.find((s) => s.sku_id === sk.sku_id);
            return {
              ...sk,
              enter_details: currentSku
                ? currentSku.enter_details
                : sk.enter_details,
            };
          })
        );
        setAvailabilityFields(getUpdatedSkus(response.data.skus));
        setBackupFieldValues((prev) => ({ ...prev, ...newBackup }));

        if (displayCheckoutPage) {
          setShowCheckout(true);
        }
      }
    } catch (error) {
      console.error("ERROR CHECKING AVAILABILITY", error);
      if (displayCheckoutPage) {
        setAvailabilityError(
          error?.response?.data?.error?.[0]?.message ?? t("generic_error_msg")
        );
      }
    } finally {
      setCheckingAvailability(false);
    }
  };

  const handleChangeSku = (value) => {
    const newIndex = Number(value.replace("opt-", ""));
    let newBackup = {};
    _.each(availabilityFields[newIndex]?.enter_details, (e_d) => {
      newBackup[e_d.field] = e_d;
    });
    setBackupFieldValues(newBackup);
    setSkuIndex(newIndex);
  };

  const renderProductContent = ({ classes }) => {
    const components = [
      ...(product.metadata?.components || [])
    ];
    return (
      <div className={classes.content} style={{ padding: "5px 30px" }}>
        <GuidebookComponents components={components} disable_card={true} />
        {loadingDetails && (
          <Skeleton
            animation="wave"
            variant="text"
            width="100%"
            height={200}
            sx={{
              transform: "none",
              mb: 1,
            }}
          />
        )}
        <InfoCard text={cancellationPolicy} />
        <AvailabilityForm
          loading={loadingDetails}
          product={product}
          callback={onFieldChange}
          productSkus={productSkus}
          skuIndex={skuIndex}
          checkingAvailability={checkingAvailability}
          handleChangeSku={handleChangeSku}
        />
        {!!availabilityError && <WarningTag label={availabilityError} />}
      </div>
    );
  };

  const actionPanel = ({ classes }) => (
    <div className={classes.actionsContainer}>
      <PrimaryButton
        label={t("check-availability")}
        disabled={checkingAvailability || skuNotAvailable}
        loading={checkingAvailability}
        onClick={() => checkAvailability(true)}
      />
    </div>
  );

  return (
    <ViatorProductContext.Provider value={{ adultRequired: adultRequired }}>
      {showCheckout && (
        <Modal open>
          <div>
            <ViatorCheckoutDialog
              product={rawProduct}
              travelDate={travelDate}
              travelers={travelerDetails}
              orderDetails={orderDetails}
              orderData={backupFieldValues}
              selectedSku={availabilityFields?.[skuIndex]}
              saveProgress={setBackupFieldValues}
              onClose={() => setShowCheckout(false)}
            />
          </div>
        </Modal>
      )}
      <BaseProductDialog
        product={product}
        onClose={onClose}
        actionPanel={actionPanel}
        renderProductContent={renderProductContent}
        contentRef={contentRef}
        addPad={true}
      />
    </ViatorProductContext.Provider>
  );
}
