import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Box,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { hexToRgb } from "helpers.js";
import PrimaryButton from "ui/base/button/PrimaryButton";
import CloseButton from "ui/base/button/CloseButton";

export default function Modal({
  title,
  text,
  customBGColor,
  hideX,
  disableEscape,
  buttons = [],
  open,
  setOpen,
  loading,
}) {
  const theme = useTheme();

  return (
    <Dialog
      open={Boolean(open)}
      disableEscapeKeyDown={disableEscape}
      keepMounted={disableEscape}
      onClose={() => setOpen(false)}
      PaperProps={{
        sx: {
          backgroundColor: customBGColor ?? theme.palette.primary.contrast,
          border: `solid 1px ${hexToRgb(theme.palette.primary.main)}`,
          borderRadius: "10px",
          textAlign: "center",
          overflowY: "auto",
          position: "relative",
        },
      }}
    >
      {!hideX && (
        <Box position={"absolute"} top={12} right={4}>
          <CloseButton onClick={() => setOpen(false)} />
        </Box>
      )}
      <DialogTitle
        sx={{
          textAlign: "center",
          fontSize: "20px",
          fontWeight: "bold",
          lineHeight: "25px",
          padding: theme.spacing(!hideX ? 5 : 4, 3, 2.5),
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {title}
        <Box sx={{ width: 24, height: 24, marginLeft: theme.spacing(2) }}>
          {loading && <CircularProgress size={24} />}
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: theme.spacing(0, 2.5, 2.5) }}>
        {text}
      </DialogContent>
      <div style={{ marginBottom: 10 }}>
        {buttons?.map((b, i) => (
          <React.Fragment key={b?.text ?? i}>
            {!!b && (
              <DialogActions sx={{ px: 3 }}>
                <PrimaryButton
                  onClick={b.onClick}
                  label={b.text}
                  loading={b.loading}
                  disabled={b.disabled}
                  variant={b.lowContrast ? "main" : "transparent"}
                />
              </DialogActions>
            )}
          </React.Fragment>
        ))}
      </div>
    </Dialog>
  );
}
