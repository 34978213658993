import React, { useEffect, useState, useRef } from "react";
import ScrollablePage from "components/ScrollablePage";
import { makeStyles } from "@mui/styles";
import { Typography, Box } from "@mui/material";
import HTMLContent from "components/HTMLContent";
import useRefDimensions from "useRefDimensions";
import defaultBGImg from "assets/article_placeholder_image.webp";
import SectionHeaderCard from "../../../../components/Cards/SectionHeaderCard";

const useStyles = makeStyles((theme) => ({
  logo: {
    height: 50,
    marginBottom: 30,
  },
  sku_box: {
    marginBottom: 12,
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  content: {
    padding: theme.spacing(5, 2.5, 0),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2.5),
    "& .ql-editor": {
      padding: "0 !important",
      "& img": { maxWidth: "100%" },
    },
  },
  btn: {
    flex: 1,
    fontWeight: "500 !important",
    fontSize: "16px !important",
    borderRadius: "30px !important",
    fontFamily: '"Montserrat", serif !important',
  },
  sku_item: {
    border: `1.5px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
    padding: 0,
    minHeight: 50,
    height: "fit-content",
    "&.disabled": {
      border: "none",
      background: theme.palette.action.disabledBackground,
    },
    "&.selected": {
      background: theme.palette.action.hover,
    },
  },
  itemIcon: {
    minWidth: "0px !important",
    marginRight: theme.spacing(1.5),
  },
  radioBtn: {
    padding: "0px !important",
    color: `${theme.palette.primary.main}!important`,
  },
  itemContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  price: {
    borderRadius: 5,
    padding: theme.spacing(0.5, 1),
    background: theme.palette.primary.main,
    color: `${theme.palette.getContrastText(
      theme.palette.primary.main
    )}!important`,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
  },
  itemDescription: {
    flex: 1,
    paddingRight: theme.spacing(1),
  },
  simpleHeadercontainer: {
    width: "100%",
    maxWidth: 800,
    paddingTop: theme.spacing(7.5),
  },
}));

export default function BaseProductDialog({
  product,
  onClose,
  actionPanel,
  renderProductContent,
  contentRef: contentRefProp,
  addPad = false,
  ...props
}) {
  const classes = useStyles();
  const ownContentRef = useRef(null);
  const contentRef = contentRefProp || ownContentRef;
  const [visibleBottom, setVisibleBottom] = useState(false);
  const contentDimensions = useRefDimensions(contentRef);

  const handleContentScroll = (e) => {
    const el = e.target;
    if (el) {
      const scrollBottom = el.scrollHeight - el.scrollTop - el.clientHeight;
      if (scrollBottom === 0 && !visibleBottom) {
        setVisibleBottom(true);
      } else if (scrollBottom > 0 && visibleBottom) {
        setVisibleBottom(false);
      }
    }
  };

  useEffect(() => {
    if (contentDimensions.width === 1 && contentDimensions.height === 2) {
      return;
    }
    if (
      contentDimensions.height === contentRef.current?.scrollHeight &&
      !visibleBottom
    ) {
      setVisibleBottom(true);
    } else if (
      contentDimensions.height < contentRef.current?.scrollHeight &&
      visibleBottom
    ) {
      setVisibleBottom(false);
    }
  }, [product, contentDimensions]);

  const header = product.header_image ? (
    <SectionHeaderCard
      disableGrayscale
      title={
        <span style={{ fontSize: "24px" }}>{product.name || ""}</span>
      }
      img={product.header_image || defaultBGImg}
      addPad={addPad}
    />
  ) : (
    <div className={classes.simpleHeadercontainer}>
      <Box px={2.5}>
        <Typography variant="h3" style={{ fontSize: "24px" }}>
          {product.name}
        </Typography>
      </Box>
    </div>
  );

  const defaultProductContent = (
    <div className={classes.content}>
      <HTMLContent v={product.description} />
    </div>
  );

  const productContent = renderProductContent
    ? renderProductContent({ classes, contentRef })
    : defaultProductContent;

  const actionPanelContent =
    typeof actionPanel === "function" ? actionPanel({ classes }) : actionPanel;

  return (
    <ScrollablePage
      onClose={onClose}
      actionPanel={actionPanelContent}
      contentRef={contentRef}
      contentType="product"
      handleContentScroll={handleContentScroll}
      header={header}
      pageContent={productContent}
      {...props}
    />
  );
}
